






























import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class NewsCard extends Vue {
  @Prop() newsObj!: Record<any, any>

  cardClicked() {
    window.open(this.newsObj.link, '_blank')
  }
}
